import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoadersService } from 'src/app/_shared/services/loaders.service';
import { environment } from 'src/environments/environment';
import { AddCustomerAddressUnavailability } from '../models/add-address-unavailability.model';
import { AddressScheduleDetails } from '../models/address-schedule-details.model';
import { CustomerAddressUnavailability } from '../models/address-unavailability.model';
import { GetPickupCalendarEventRequest } from '../models/get-pickup-calendar-event-request.model';
import { InvoiceOdoo } from '../models/Invoice-odoo.model';
import { PickupCalendarEvent } from '../models/pickup-calendar-event.model';

@Injectable({
  providedIn: 'root',
})
export class SchedulesService {
  constructor(private httpClient: HttpClient, private loadersService: LoadersService) {}

  GetPickupCalendarEvents(addressIds: number[], date: Date, loader: string): Observable<Array<PickupCalendarEvent>> {
    let request: GetPickupCalendarEventRequest = {
      date: date,
      addressIds: addressIds,
    };

    this.loadersService.show(loader);

    return this.httpClient
      .post<Array<PickupCalendarEvent>>(environment.apiUrl + 'schedules/addresses/calendar-events/', request)
      .pipe(finalize(() => this.loadersService.hide(loader)));
  }

  getAddressDetails(id: number, loader: string): Observable<AddressScheduleDetails> {
    this.loadersService.show(loader);
    return this.httpClient
      .get<AddressScheduleDetails>(`${environment.apiUrl}schedules/addresses/${id}`)
      .pipe(finalize(() => this.loadersService.hide(loader)));
  }

  addOrUpdateUnavailability(request: CustomerAddressUnavailability, loader: string): Observable<any> {
    this.loadersService.show(loader);

    return this.httpClient
      .post(`${environment.apiUrl}schedules/addresses/unavailability`, request, { observe: 'response' })
      .pipe(finalize(() => this.loadersService.hide(loader)));
  }

  addOrUpdateUnavailabilities(addressId: number, request: CustomerAddressUnavailability[], loader: string): Observable<any> {
    this.loadersService.show(loader);

    return this.httpClient
      .post(`${environment.apiUrl}schedules/addresses/${addressId}/unavailabilities`, request, { observe: 'response' })
      .pipe(finalize(() => this.loadersService.hide(loader)));
  }

  removeUpdateUnavailability(id: number, loader: string): Observable<any> {
    this.loadersService.show(loader);

    return this.httpClient
      .delete(`${environment.apiUrl}schedules/addresses/unavailability/${id}`, { observe: 'response' })
      .pipe(finalize(() => this.loadersService.hide(loader)));
  }

  addNewUnavailbility(request: AddCustomerAddressUnavailability) {
    return this.httpClient.post(`${environment.apiUrl}schedules/addresses/unavailability`, request, { observe: 'response' });
  }
}
