import { Injectable } from '@angular/core';
import { UserManager, UserManagerSettings, User, WebStorageStateStore } from 'oidc-client';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserInfo } from '../models/user-info.model';
import { CustomerPortalUserRole } from '../models/user-role';
import { LocalStorageService } from './local-storage.service';
import { MaintenanceService } from './maintenance.service';
import { BaseService } from './_base.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BaseService {
  private manager = new UserManager(this.getClientSettings());
  private user: User | null;
  private currentUserSubject: BehaviorSubject<UserInfo>;

  constructor(private maintenanceService: MaintenanceService, private localStorageService: LocalStorageService) {
    super();
    const storedUser = this.localStorageService.load(LocalStorageService.AUTH_CURRENT_USER);

    if (storedUser) {
      this.user = storedUser;
    }

    this.manager.getUser().then((user) => {
      this.user = user;
      this.localStorageService.store(LocalStorageService.AUTH_CURRENT_USER, this.user);
    });

    this.manager.events.addUserLoaded((args) => {
      this.manager.getUser().then((user) => {
        this.user = user;
        this.localStorageService.store(LocalStorageService.AUTH_CURRENT_USER, this.user);
      });
    });
    const userInfo = this.localStorageService.load(LocalStorageService.AUTH_USER_INFO);
    this.currentUserSubject = new BehaviorSubject<UserInfo>(userInfo);
  }

  login() {
    return this.manager.signinRedirect();
  }

  logout() {
    this.localStorageService.remove(LocalStorageService.AUTH_CURRENT_USER);
    this.user = null;
    this.localStorageService.remove(LocalStorageService.AUTH_USER_INFO);
    this.currentUserSubject.next(null);
    return this.manager.signoutRedirect();
  }

  getUserName(): string {
    return this.user != null ? this.user.profile['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'] : '';
  }

  hasAccess(allowedRoles: Array<CustomerPortalUserRole>): boolean {
    let result = false;
    allowedRoles.forEach((x) => {
      // tslint:disable-next-line:no-bitwise
      if (!!this.getUserInfo && (this.getUserInfo.roles & x) === x) {
        result = true;
      }
    });
    return result;
  }

  public get getUserInfo(): UserInfo {
    return !!this.currentUserSubject ? this.currentUserSubject.value : null;
  }

  getAuthorizationHeaderValue(): string {
    if (!this.user) {
      return null;
    }
    return `${this.user.token_type} ${this.user.id_token}`;
  }

  async completeAuthentication() {
    this.user = await this.manager.signinRedirectCallback();
    if (this.user) {
      this.localStorageService.store(LocalStorageService.AUTH_CURRENT_USER, this.user);
      const userInfo = await this.maintenanceService.UseInfoAsync(this.getUserName());
      this.localStorageService.store(LocalStorageService.AUTH_USER_INFO, userInfo);
      this.currentUserSubject.next(userInfo);
    }
    await this.manager.clearStaleState();
  }

  async completeSilentRenewal() {
    await this.manager.signinSilentCallback();
    await this.manager.clearStaleState();
  }

  storeUnaturizedRoute(route: string) {
    this.localStorageService.store(LocalStorageService.LAST_UNAUTHETNICATED_ROUTE, route);
  }

  isAuthenticated(): boolean {
    if (!this.user) {
      return false;
    }

    const expiryDateInMiliseconds = this.user.expires_at * 1000;
    return !isNaN(expiryDateInMiliseconds) && expiryDateInMiliseconds > Date.now();
  }

  private getClientSettings(): UserManagerSettings {
    return {
      authority: environment.ssoUrl,
      client_id: 'customerportal.web',
      redirect_uri: environment.localUrl + '/auth/auth-callback',
      response_type: 'id_token token',
      scope: 'openid profile custom.userinfo nexus.api.access',
      userStore: new WebStorageStateStore({ store: window.localStorage }),
      staleStateAge: 300,
      automaticSilentRenew: true,
      silent_redirect_uri: environment.localUrl + '/auth/silent-renew-callback',
    };
  }
}
