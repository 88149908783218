<div class="loader-container">
  <div class="loader-backgroud" [hidden]="!isVisible">
    <svg width="100" height="100" viewBox="0 0 120 120" class="logo">
      <polygon points="80.34 96.14 60 116.48 38.44 94.92 38.44 47.89 60 72.69 80.34 96.14" />
      <polygon points="116.48 60 100.33 76.15 100.33 43.87 116.48 60" />
      <polygon points="22.04 41.48 22.04 78.54 3.52 60 22.04 41.48" />
      <polygon points="83.92 27.45 83.92 75.23 60 47.68 39.47 24.07 60 3.52 83.92 27.45" />
    </svg>
    <svg width="100" height="100" viewBox="0 0 120 120" class="logo-animation">
      <polygon points="80.34 96.14 60 116.48 38.44 94.92 38.44 47.89 60 72.69 80.34 96.14" />
      <polygon points="116.48 60 100.33 76.15 100.33 43.87 116.48 60" />
      <polygon points="22.04 41.48 22.04 78.54 3.52 60 22.04 41.48" />
      <polygon points="83.92 27.45 83.92 75.23 60 47.68 39.47 24.07 60 3.52 83.92 27.45" />
    </svg>
    <div class="progress-bar-container">
      <div class="progress-bar" *ngIf="value && maxValue && progress && showProgressBar">
        <span [ngStyle]="{ width: progress + '%' }">
          <div *ngIf="progress">{{ progress }} %</div>
        </span>
      </div>
    </div>
  </div>

  <ng-content></ng-content>
</div>
