import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { SelectItem } from 'primeng/api';
import { EnumExtension } from '../utils/enum.extension';

@Pipe({ name: 'flagEnumText' })

export class FlagEnumText implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) { }

  transform(value: any, selectItems: SelectItem[]) {

    if (!value || !selectItems) {
      return value;
    }

    let selectedValues = EnumExtension.getSelectedValues(value);
    let selectedLabels = selectedValues.map(x => selectItems.find(y => y.value == x).label);
    const result = selectedLabels.join(' + ')

    return result;
  }
}
