<div class="left-side">
  <div class="logo" [class.expand]="expand">
    <img src="../../../assets/CP_logo_white.svg" />
  </div>
  <app-breadcrumb></app-breadcrumb>
</div>

<div class="rightSide">
  <div class="block" pTooltip="Open fullscreen" tooltipPosition="bottom" (click)="openFullscreen()" *ngIf="!isFullScreen">
    <i class="fa fa-expand icon"></i>
  </div>
  <div class="block" pTooltip="Close fullscreen" tooltipPosition="bottom" (click)="closeFullscreen()" *ngIf="isFullScreen">
    <i class="fa fa-compress icon"></i>
  </div>

  <div class="block">
    <i class="fa fa-language icon"></i>
    <div class="dropdown-content">
      <div
        *ngFor="let lang of languages$ | async"
        (click)="setLanguage(lang.culture)"
        [class.selected]="lang.culture === translate.currentLang"
      >
        {{ lang.name }}
      </div>
      <div (click)="showTranslationsKeys()"
        [class.selected]="'keys' === translate.currentLang">
        Keys
      </div>
    </div>
  </div>

  <div class="block" pTooltip="{{ userName }}" tooltipPosition="bottom" showDelay="150">
    <i class="fa fa-user-circle icon"></i>
  </div>

  <a [href]="environment.ssoUrl" class="block svg divider" pTooltip="Choose system" tooltipPosition="bottom">
    <img width="25" src="./../../../assets/topbar_icons/choose_system.svg" />
  </a>

  <div class="block svg" pTooltip="Logout" tooltipPosition="bottom" (click)="logout()">
    <img width="32" src="./../../../assets/topbar_icons/logout.svg" />
  </div>
</div>
