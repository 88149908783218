import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ListboxModule } from 'primeng/listbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';
import { InputTextModule } from 'primeng/inputtext';
import { SharedModule } from '../_shared/shared.module';
import { ArticleUnitTypePipe } from '../_shared/pipes/article-unit-type.pipe';
import { HistoryComponent } from './components/history.component';
import { InvoicesComponent } from './components/invoices/invoices.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { CalendarModule } from 'primeng/calendar';
import { TranslateModule } from '@ngx-translate/core';
import { ScheduleComponent } from './components/schedule/schedule.component';
import { FullCalendarModule } from 'primeng/fullcalendar';
import { AgmOverlays } from 'agm-overlays';
import { AgmCoreModule } from '@agm/core';
import { environment } from 'src/environments/environment';
import { AddUnavailabilityComponent } from './components/schedule/add-unavailability/add-unavailability.component';
import { DialogService } from 'primeng/dynamicdialog';
import { ConfirmationService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { TooltipModule } from 'primeng/tooltip';

@NgModule({
  declarations: [HistoryComponent, InvoicesComponent, ScheduleComponent, AddUnavailabilityComponent],
  imports: [
    CommonModule,
    SharedModule,
    ListboxModule,
    FormsModule,
    TableModule,
    InputTextModule,
    MultiSelectModule,
    CalendarModule,
    TranslateModule,
    FullCalendarModule,
    AgmOverlays,
    ReactiveFormsModule,
    ConfirmDialogModule,
    TooltipModule,
    AgmCoreModule.forRoot({
      apiKey: environment.googleMapsApiKey,
      libraries: ['places', 'drawing', 'geometry'],
    }),
  ],
  providers: [DatePipe, ArticleUnitTypePipe, DialogService, ConfirmationService],
})
export class HistoryModule {}
