import { AfterViewInit, Component, Inject, Input } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/_shared/services/auth.service';
import { LocalizationService } from 'src/app/_shared/services/localization.service';
import { Language } from 'src/app/_shared/models/language.model';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss'],
})
export class TopNavComponent implements AfterViewInit {
  elem;
  isFullScreen = false;
  userName = '';
  environment = environment;

  languages$: Observable<Language[]>;

  @Input() expand: boolean;

  constructor(
    @Inject(DOCUMENT) private document: any,
    private authService: AuthService,
    public localizationService: LocalizationService,
    public translate: TranslateService
  ) {}

  ngAfterViewInit(): void {
    this.elem = document.documentElement;

    setTimeout(() => {
      this.userName = this.authService.getUserName();
      this.languages$ = this.localizationService.getAllLanguages();
    }, 0);
  }

  logout() {
    this.authService.logout();
  }

  openFullscreen() {
    if (this.elem.requestFullscreen) {
      this.elem.requestFullscreen();
    } else if (this.elem.mozRequestFullScreen) {
      this.elem.mozRequestFullScreen();
    } else if (this.elem.webkitRequestFullscreen) {
      this.elem.webkitRequestFullscreen();
    } else if (this.elem.msRequestFullscreen) {
      this.elem.msRequestFullscreen();
    }
    this.isFullScreen = !this.isFullScreen;
  }

  closeFullscreen() {
    if (this.document.exitFullscreen) {
      this.document.exitFullscreen();
    } else if (this.document.mozCancelFullScreen) {
      this.document.mozCancelFullScreen();
    } else if (this.document.webkitExitFullscreen) {
      this.document.webkitExitFullscreen();
    } else if (this.document.msExitFullscreen) {
      this.document.msExitFullscreen();
    }
    this.isFullScreen = !this.isFullScreen;
  }

  setLanguage(lang: string) {
    this.localizationService.setLanguage(lang);
  }
  showTranslationsKeys() {
    this.localizationService.showTranslationsKeys();
  }
}
