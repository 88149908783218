import { AuthService } from './../../../services/auth.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CustomerPortalUserRole } from 'src/app/_shared/models/user-role';

@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss'],
})
export class LeftMenuComponent implements OnInit {
  collapsedList: Array<boolean> = [false, false, false, false, false, false, false];
  lastIndex = null;

  @Input() expand: boolean;

  @Output()
  expandEvent = new EventEmitter<boolean>();

  menu: { label: string; routerLink: string; icon?: string; iconSrc?: string; children?: any[]; class?: string; hasAccsess?: boolean }[] = [
    { label: 'History', routerLink: '/history', iconSrc: this.iconsSrcPath('history'), hasAccsess: this.hasAccess([CustomerPortalUserRole.Customer]) },
    {
      label: 'Invoices',
      routerLink: '/invoices',
      iconSrc: this.iconsSrcPath('pricings'),
      hasAccsess: this.hasAccess([CustomerPortalUserRole.InvoiceViewer]),
    },
    { label: 'Schedule', routerLink: '/schedule', iconSrc: this.iconsSrcPath('calendar'), hasAccsess: this.hasAccess([CustomerPortalUserRole.Customer]) },
  ];

  constructor(private authService: AuthService) {}

  iconsSrcPath(name: string) {
    return `../../../../../assets/icons/menu/${name}.svg`;
  }

  ngOnInit() {}

  collapse(index: number, collapseParent: boolean = true) {
    this.collapsedList.forEach((x, i) => {
      if (i !== index && collapseParent) {
        this.collapsedList[i] = false;
      }
    });
    this.collapsedList[index] = !this.collapsedList[index];
    this.lastIndex = index;
  }

  hasAccess(allowedRole: CustomerPortalUserRole[]): boolean {
    const have = this.authService.hasAccess(allowedRole);
    return have;
  }

  onHideMenu() {
    this.expand = false;
    this.expandEvent.emit(this.expand);
  }

  onExpandMenu() {
    this.collapsedList.forEach((x, i) => {
      this.collapsedList[i] = false;
    });
    this.expand = !this.expand;
    this.expandEvent.emit(this.expand);
  }

  logout() {
    this.authService.logout();
  }

  getMenuItemIconSrc(item: any, isActive: boolean): string {
    let iconPath = <string>item.iconSrc;

    if (isActive) {
      const extensionIndex = iconPath.lastIndexOf('.');
      return iconPath.substring(0, extensionIndex) + '_active' + iconPath.substring(extensionIndex, iconPath.length);
    }

    return iconPath;
  }
}
