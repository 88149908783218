import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanLoad, Route, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { LocalStorageService } from '../services/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  canLoad(route: Route): boolean {
    return this.authService.isAuthenticated();
  }

  constructor(private authService: AuthService, private router: Router, private localStorageService: LocalStorageService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!this.authService.isAuthenticated()) {
      this.authService.storeUnaturizedRoute(state.url);
      this.router.navigate(['/auth/login']);
      return false;
    }

    if (route.data.expectedRoles && !this.authService.hasAccess(route.data.expectedRoles)) {
      this.router.navigate(['/error/401']);
      return false;
    }
    return true;
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(childRoute, state);
  }
}
