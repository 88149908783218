import { Injectable } from '@angular/core';
import { Message, MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class ToastsService {
  private visibleToasts: Message[] = [];

  // private translateService: TranslateService
  constructor(private messageService: MessageService) {}

  // this.translateService.instant('toasts.titleInfo')
  showSuccess(details: string, title: string = 'Info') {
    const message = { severity: 'success', summary: title, detail: details };
    if (this.canShowToast(message)) {
      this.messageService.add(message);
    }
  }

  // this.translateService.instant('toasts.titleSuccess')
  showInfo(details: string, title: string = 'Success') {
    const message = { severity: 'info', summary: title, detail: details };
    if (this.canShowToast(message)) {
      this.messageService.add(message);
    }
  }

  // this.translateService.instant('toasts.titleWarn')
  showWarn(details: string, title: string = 'Warning') {
    const message = { severity: 'warn', summary: title, detail: details };
    if (this.canShowToast(message)) {
      this.messageService.add(message);
    }
  }

  // this.translateService.instant('toasts.titleError')
  showError(details: string, title: string = 'Error') {
    const message = { severity: 'error', summary: title, detail: details };
    if (this.canShowToast(message)) {
      this.messageService.add(message);
    }
  }

  onToastClose(message: Message) {
    this.visibleToasts = this.visibleToasts.filter((t) => t.severity !== message.severity && t.detail !== message.detail);
  }

  private canShowToast(message: Message): boolean {
    const sameToast = this.visibleToasts.find((t) => t.severity === message.severity && t.detail === message.detail);
    if (!sameToast) {
      this.visibleToasts.push(message);
      return true;
    }
    return false;
  }
}
