import { Injectable } from '@angular/core';

@Injectable()
export class LocalStorageService {
  static readonly AUTH_USER_INFO = 'auth.userInfo';
  static readonly AUTH_CURRENT_USER = 'auth.currentUser';
  static readonly PREFERENCES_LANGUAGE = 'preferences.language';
  static readonly LAST_UNAUTHETNICATED_ROUTE = 'last.unauthenticated.route';

  store(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  load(key: string) {
    const valueString = localStorage.getItem(key);
    if (valueString) {
      return JSON.parse(valueString);
    }
    return null;
  }

  remove(key: string) {
    localStorage.removeItem(key);
  }
}
