import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SelectItem } from 'primeng/api/selectitem';
import { DatePipe } from '@angular/common';
import { Address } from 'src/app/_shared/models/address.model';
import { ToastsService } from 'src/app/_shared/services/toasts.service';
import { ArticleUnitTypePipe } from 'src/app/_shared/pipes/article-unit-type.pipe';
import { HistoryService } from 'src/app/_shared/services/data.service';
import { VehicleType } from 'src/app/_shared/models/vehicle-type.enum';
import { TimeSchedule } from 'src/app/_shared/models/time-schedule.enum';
import { CalendarEventType } from 'src/app/_shared/models/calendar-event-type.enum';
import { WeekRepetition } from 'src/app/_shared/models/week-repetition.enum';
import { PickupCalendarEvent } from '../../models/pickup-calendar-event.model';
import { UnifiedPickupEventData } from '../../models/unified-pickup-event-data.model';
import { PickupAddress } from '../../models/pickup-near-address.model';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { FullCallendarConsts } from 'src/app/_shared/constants/full-calendar.const';
import { ArticleCategory } from 'src/app/_shared/models/article-category.enum';
import { SchedulesService } from '../../services/schedules.service';
import { List } from 'linqts';
import { AddressScheduleDetails } from '../../models/address-schedule-details.model';
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { FormGroupExtension } from 'src/app/_shared/utils/form-group.extension';
import { CustomerAddressUnavailability } from '../../models/address-unavailability.model';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { DialogService } from 'primeng/dynamicdialog';
import { AddUnavailabilityComponent } from './add-unavailability/add-unavailability.component';
import { TooltipModule } from 'primeng/tooltip';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import autoTable from 'jspdf-autotable';
import '.././../../../assets/font/Raleway/Raleway-Regular-normal.js'

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss'],
})
export class ScheduleComponent implements AfterViewInit, OnDestroy {
  @ViewChild('fullCalendar', { static: false }) fullCalendar: any;

  addressesLoader = 'addressesLoader';
  scheduleLoader = 'scheduleLoader';
  detailsLoader = 'detailsLoader';
  private sub: any;
  addressId: number;
  DefaultDate: Date;
  tomorrow = moment().add(1, 'days').toDate();
  shouldSelectAll: false;

  editForm: FormGroup;

  VehicleType = VehicleType;
  TimeSchedule = TimeSchedule;
  CalendarEventType = CalendarEventType;
  WeekRepetition = WeekRepetition;

  showSheduledPickups = true;

  allCalendarEvents: PickupCalendarEvent[] = [];
  hoveredEvent: UnifiedPickupEventData = null;

  selectedAddressData: AddressScheduleDetails = null;

  addresses: PickupAddress[] = [];
  visibleAddresses: PickupAddress[] = [];
  selectedAddresses: PickupAddress[] = [];

  articleCategories: SelectItem[] = [];
  workdays: SelectItem[] = [];
  repetitions: SelectItem[] = [];
  pickupTypes: SelectItem[] = [];
  allContainers: SelectItem[] = [];
  activeFormAvailableExchangeContainers: SelectItem[] = [];
  allFormContracts: SelectItem[] = [];
  activeFormContracts: SelectItem[] = [];
  goodsDestinations: SelectItem[] = [];
  transporters: SelectItem[] = [];
  warehouses: SelectItem[] = [];

  calendarEvents: any[] = [];
  calendarOptions: any = {
    plugins: [dayGridPlugin, interactionPlugin],
    defaultDate: new Date(),
    customButtons: {
      printButton: {
        text: 'PDF',
        click: () => {
          this.printCallendar();
        },
      }
    },
    header: {
      left: 'printButton ',
      center: 'title',
      right: 'prev,next',
    },
    eventDisplay: 'background',
    displayEventEnd: true,
    weekends: true,
    displayEventTime: false,
    firstDay: 1,
    weekNumbers: false,
    expandRows: true,
    contentHeight: 1.6,
    eventMouseEnter: (e) => {
      this.hoveredEvent = e.event.extendedProps.data;
    },
    eventMouseLeave: (e) => {
      this.hoveredEvent = null;
    },
  };
  calendarLocale = FullCallendarConsts.getCallendarLocale();

  constructor(
    private toastsService: ToastsService,
    private historyService: HistoryService,
    private datePipe: DatePipe,
    public translate: TranslateService,
    private articleUnitTypePipe: ArticleUnitTypePipe,
    private schedulesService: SchedulesService,
    private dialogService: DialogService,
    private translateService: TranslateService
  ) {
    this.workdays = [
      { label: 'Mon', value: TimeSchedule.Monday },
      { label: 'Tue', value: TimeSchedule.Tuesday },
      { label: 'Wed', value: TimeSchedule.Wednesday },
      { label: 'Thu', value: TimeSchedule.Thursday },
      { label: 'Fri', value: TimeSchedule.Friday },
      { label: 'Sat', value: TimeSchedule.Saturday },
      { label: 'Sun', value: TimeSchedule.Sunday },
    ];
    this.repetitions = [
      { label: '1 week', value: WeekRepetition.EachWeek },
      { label: '2 weeks', value: WeekRepetition.Each2Weeks },
      { label: '3 weeks', value: WeekRepetition.Each3Weeks },
      { label: '4 weeks', value: WeekRepetition.Each4Weeks },
      { label: '8 weeks', value: WeekRepetition.Each8Weeks },
      { label: '12 weeks', value: WeekRepetition.Each12Weeks },
    ];
    this.pickupTypes = [
      { label: 'Exchange', value: VehicleType.ContainersCollector },
      { label: 'Dump', value: VehicleType.DumpCollector },
    ];
    this.articleCategories = [
      { label: 'Cat1', value: ArticleCategory.Cat1 },
      { label: 'Cat2', value: ArticleCategory.Cat2 },
      { label: 'Cat3', value: ArticleCategory.Cat3 },
      { label: 'Cat4', value: ArticleCategory.Cat4 },
      { label: 'Cat5', value: ArticleCategory.Cat5 },
    ];
  }

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    this.getDropdownDependencies();
    this.bindCalendarEvents();
    this.getAddresses();
  }

  printCallendar(){
    let calendarToPrint: any = this.fullCalendar.el.nativeElement;

    var lastStyle = calendarToPrint.style;
    calendarToPrint.style.position = 'fixed';
    calendarToPrint.style.width = '960px';
    calendarToPrint.style.height = 'auto';

    html2canvas(calendarToPrint).then((canvas) => {
      const a4Width = 210;
      const a4Height = 297;
      const padding = 10;
      const legendHeight = 30;
      const maxImageWidth = a4Width - padding * 2;
      const maxImageHeight = a4Height - padding * 2 - legendHeight;
      var ratioY = maxImageHeight / canvas.height;
      var ratioX = maxImageWidth / canvas.width;
      var ratio = ratioX > ratioY ? ratioY : ratioX;

      var imgHeight = canvas.height * ratio;
      var imgWidth = canvas.width * ratio;

      const image = canvas.toDataURL('image/png');

      const PDF = new jsPDF('p', 'mm', 'a4');

      var offsetX = (a4Width - imgWidth) / 2;
      var offsetY = padding;
      
      PDF.addImage(image, 'PNG', offsetX, offsetY, imgWidth, imgHeight);

      class LegendEntry {
        description: string;
        color: string;
        translateService: TranslateService;

        constructor(description: string, color: string) {
          this.description = description;
          this.color = color;
        }
      }

      class AddressesLegend {
        letter: string;
        customerName: string;
        address: string;

        constructor(letter: string, customerName: string, address: string){
          this.letter = letter;
          this.customerName = customerName;
          this.address = address;
        }
      }

      PDF.setFillColor('white');
      PDF.rect(0, 0, 70, 18, "F");
      PDF.rect(a4Width, 0, -70, 18, "F");

      var legendEntries = [
        new LegendEntry(this.translateService.instant('management.color.scheduledExchange'), "#3F51B5"),
        new LegendEntry(this.translateService.instant("management.color.scheduledDump"), "#795548"),
        new LegendEntry(this.translateService.instant("management.color.onDemandExchange"), "#9C27B0"),
        new LegendEntry(this.translateService.instant("management.color.onDemandDump"), "#FFA000"),
        new LegendEntry(this.translateService.instant("management.color.onDemandPlanned"), "#4CAF50"),
        new LegendEntry(this.translateService.instant("management.color.vacationSlashUnavailability"), "#f44336"),
        new LegendEntry(this.translateService.instant("management.color.unavailability.backOffice"), "#880015"),
      ];

      const rectWidth = 10;
      const entriesPerColumn = 4;
      var topLegend = offsetY + imgHeight + padding;
      var legendColumnWidth = maxImageWidth / Math.ceil(legendEntries.length / entriesPerColumn);

      PDF.setFontSize(12);
      var lineHeight = PDF.getTextDimensions("W", {fontSize: 12}).h + 2;
      for (var i = 0; i < legendEntries.length; i++) {
        var topEntry = topLegend + Math.floor(i % entriesPerColumn) * lineHeight;
        var column = Math.floor(i / entriesPerColumn);
        PDF.setFillColor(legendEntries[i].color);
        PDF.rect(
          padding + column * legendColumnWidth, 
          topEntry - lineHeight / 3, 
          rectWidth, 
          lineHeight / 3, 
          "F");
        PDF.setTextColor('black');
        PDF.text(legendEntries[i].description, padding + column * legendColumnWidth + rectWidth + 3, topEntry);
      }

      PDF.setFont("Raleway-Regular");
      const selectedAddressesToPrint = Array<AddressesLegend>();
      this.selectedAddresses.forEach( x => selectedAddressesToPrint.push(new AddressesLegend(x.letterId, x.customerName, x.street)));

      if(selectedAddressesToPrint.length>0){
        autoTable(PDF, 
          {
            startY: (entriesPerColumn * lineHeight + topLegend),
            head: [['', 
                    this.translateService.instant('web.common.addressName'), 
                    this.translateService.instant('common.street')]],
            body: selectedAddressesToPrint.map(x => [x.letter, x.customerName, x.address]),
            styles: {font: "Raleway-Regular"},
            pageBreak: 'auto',
            headStyles: {fillColor: [0x8b,0xc3,0x4a], fontSize: 12}
          }, );
      }
      
       PDF.save("planned-schedules" + this.datePipe.transform( this.fullCalendar.calendar.component.props.currentDate, '-MMMMyyyy')+".pdf");
    })
    calendarToPrint.style = lastStyle;
  }

  bindCalendarEvents() {
    const prevButton = this.fullCalendar.el.nativeElement.getElementsByClassName('fc-prev-button');
    const nextButton = this.fullCalendar.el.nativeElement.getElementsByClassName('fc-next-button');
    nextButton[0].addEventListener('click', () => {
      this.getEvents(this.fullCalendar.calendar.view.currentStart);
      this.bindCalendarEvents();
    });
    prevButton[0].addEventListener('click', () => {
      this.getEvents(this.fullCalendar.calendar.view.currentStart);
      this.bindCalendarEvents();
    });
  }

  unBindCalendarEvents() {
    const prevButton = this.fullCalendar.el.nativeElement.getElementsByClassName('fc-prev-button');
    const nextButton = this.fullCalendar.el.nativeElement.getElementsByClassName('fc-next-button');
    nextButton[0].removeEventListener('click', () => {
      this.getEvents(this.fullCalendar.calendar.view.currentStart);
    });
    prevButton[0].removeEventListener('click', () => {
      this.getEvents(this.fullCalendar.calendar.view.currentStart);
    });
  }

  getDropdownDependencies() {
    //this.assetsService.GetAllActiveDefinitions('noLoader').subscribe(res => {
    //  this.allContainers = res.map(x => ({ label: x.name, value: x.id, extraValue: x }));
    //}, error => this.toastsService.showError(error));
  }

  getAddresses() {
    this.historyService.getAddresses(this.scheduleLoader).subscribe(
      (result) => {
        this.addresses = result.map((x) => ({ id: x.id, name: x.name, customerName: x.name, city: x.name, street: x.street }));
        this.addresses.forEach((x) => (x.letterId = this.getIndexLetter(this.addresses.indexOf(x))));
        this.selectedAddresses = [];
        this.visibleAddresses = [...this.addresses];
        this.getEvents(this.fullCalendar.calendar.view.currentStart);
      },
      (error) => {
        this.toastsService.showError(error);
      }
    );
  }

  onNeighbourSelectionChange() {
    this.onSetCalendarEvents();
  }

  onAddressSelected(id: number) {
    this.getAddressDetails(id);
  }

  getAddressDetails(id: number) {
    this.schedulesService.getAddressDetails(id, this.detailsLoader).subscribe(
      (res) => {
        this.selectedAddressData = res;
        this.selectedAddressData.letterId = this.addresses.find((x) => x.id == res.id).letterId;
        this.editForm = this.initForm(this.selectedAddressData);
      },
      (error) => this.toastsService.showError(error)
    );
  }

  getEvents(date: Date) {
    let ids = this.addresses.map((x) => x.id);
    this.schedulesService.GetPickupCalendarEvents(ids, date, this.scheduleLoader).subscribe(
      (res) => {
        res.forEach((e) => (e.data.title = e.addressId ? this.addresses.find((x) => x.id == e.addressId).letterId + ' ' + e.name : e.name));
        this.allCalendarEvents = res;
        this.onSetCalendarEvents();
      },
      (error) => this.toastsService.showError(error)
    );
  }

  onSetCalendarEvents() {
    let visibleAddresses = new List(this.selectedAddresses).Select((x) => x.id);
    let visibleEvents = this.allCalendarEvents.filter((x) => !x.addressId || visibleAddresses.Contains(x.addressId));
    this.calendarEvents = visibleEvents.map((e) => ({
      groupId: e.identifier,
      title: e.data.title,
      start: e.startDate,
      end: e.endDate,
      data: e.data,
      backgroundColor: e.color,
    }));
  }

  private initForm(address: AddressScheduleDetails): FormGroup {
    const form = new FormGroup({
      customerAddressUnavailability: new FormArray([]),
    });
    if (address.unavailabilities) {
      address.unavailabilities.reverse().forEach((x, i) => {
        const daysUnavailabilityForm = new FormGroup({
          id: new FormControl(x.id),
          from: new FormControl(new Date(x.from), Validators.required),
          to: new FormControl(new Date(x.to), Validators.required),
          comment: new FormControl(x.comment, Validators.required),
          status: new FormControl(x.status),
        });
        (form.controls.customerAddressUnavailability as FormArray).push(daysUnavailabilityForm);
      });
    }

    return form;
  }

  onSelectAllChanged(condition) {
    if (condition) {
      this.selectedAddresses = [...this.addresses];
    } else {
      this.selectedAddresses = [];
    }

    this.onNeighbourSelectionChange();
  }

  onSearchAddress(event) {
    let query = event.target.value.toLowerCase();

    const result = new List(this.addresses)
      .Where(
        (x) =>
          x.name.toLowerCase().indexOf(query) !== -1 ||
          x.city.toLowerCase().indexOf(query) !== -1 ||
          x.street.toLowerCase().indexOf(query) !== -1 ||
          x.customerName.toLowerCase().indexOf(query) !== -1
      )
      .ToArray();

    this.visibleAddresses = [...result];
  }

  getIndexLetter(index: number): string {
    let abcTab = [
      'A',
      'B',
      'C',
      'D',
      'E',
      'F',
      'G',
      'H',
      'I',
      'J',
      'K',
      'L',
      'M',
      'N',
      'O',
      'P',
      'Q',
      'R',
      'S',
      'T',
      'U',
      'V',
      'W',
      'X',
      'Y',
      'Z',
    ];

    if (index < abcTab.length) {
      return abcTab[index];
    } else {
      return abcTab[Math.floor(index / abcTab.length) - 1] + abcTab[index % abcTab.length];
    }
  }

  onAddNew(selectedAddressId: number) {
    const ref = this.dialogService.open(AddUnavailabilityComponent, {
      header: this.translate.instant('common.newUnavailabilityRequest'),
      data: {
        selectedAddressId: selectedAddressId,
      },
    });

    ref.onDestroy.subscribe((res: any) => {
      this.getEvents(this.fullCalendar.calendar.view.currentStart);
    });

    ref.onClose.subscribe((res: any) => {
      this.onAddressSelected(selectedAddressId);
    });
  }
}
