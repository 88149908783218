<div class="white-container p-0">
  <app-loader [name]="invoicesLoader">
    <div class="p-10">
      <p-table
        #dt
        [columns]="cols"
        [value]="invoices"
        [scrollable]="true"
        [resizableColumns]="true"
        (onSort)="tableService.onSort($event.field, $event.order)"
        [(sortField)]="tableService.sortField"
        [(sortOrder)]="tableService.sortOrder"
        [(filters)]="tableService.tableFilters"
        (onFilter)="tableService.onFilter()"
        [globalFilterFields]="cols | map: 'field'"
        scrollHeight="calc(100vh - 245px)">
        <ng-template pTemplate="colgroup" let-columns>
          <colgroup>
            <col style="width: 20px;" />
            <col *ngFor="let col of columns" [style.width]="col.width" />
          </colgroup>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th></th>
            <th *ngFor="let col of columns" [pSortableColumn]="col.field" class="headerRow" [ngSwitch]="col.field">
              {{ col.header | translate }}
              <div *ngSwitchCase="'action'"></div>
              <p-sortIcon *ngSwitchDefault [field]="col.field"></p-sortIcon>
            </th>
          </tr>
          <tr>
            <th></th>
            <th *ngFor="let col of columns" [ngSwitch]="col.field">
              <div *ngSwitchCase="'action'" class="centercontent"></div>
              <p-multiSelect
                *ngSwitchCase="'status'"
                [options]="types"
                [filter]="false"
                [maxSelectedLabels]="1"
                appendTo="body"
                [panelStyle]="{ minWidth: '15em' }"
                [style]="{ minWidth: '100%' }"
                defaultLabel="All"
                [selectedItemsLabel]="'{0} items'"
                [showToggleAll]="false"
                appHighlightInputWithValue
                [(ngModel)]="tableService.filterValues[col.field]"
                (onChange)="dt.filter($event.value, col.field, 'in')"
              >
              </p-multiSelect>

              <p-calendar
                *ngSwitchCase="'dateCreated'"
                #rangeFilter1
                selectionMode="range"
                appendTo="body"
                placeholder="{{ 'Filter' }}"
                [locale]="tableService.calendarLocale"
                (onSelect)="dt.filter(rangeFilter1.value, col.field, 'dateRangeFilter')"
                [(ngModel)]="tableService.filterValues[col.field]"
                appHighlightInputWithValue
                (onClearClick)="dt.filter(null, col.field, 'dateRangeFilter')"
                [style]="{ minWidth: '100%' }"
                dateFormat="dd.mm.yy"
                [showButtonBar]="true"
                >
                </p-calendar>

                <p-calendar
                *ngSwitchCase="'invoiceDate'"
                #rangeFilter1
                selectionMode="range"
                appendTo="body"
                placeholder="{{'common.date' | translate}}"
                [locale]="tableService.calendarLocale"
                (onSelect)="dt.filter(rangeFilter1.value, col.field, 'dateRangeFilter')"
                [(ngModel)]="tableService.filterValues[col.field]"
                appHighlightInputWithValue
                (onClearClick)="dt.filter(null, col.field, 'dateRangeFilter')"
                [style]="{ minWidth: '100%' }"
                dateFormat="dd.mm.yy"
                [showButtonBar]="true"
              >
              </p-calendar>

              <p-calendar
              *ngSwitchCase="'invoiceDateDue'"
              #rangeFilter1
              selectionMode="range"
              appendTo="body"
              placeholder="{{'common.date' | translate}}"
              [locale]="tableService.calendarLocale"
              (onSelect)="dt.filter(rangeFilter1.value, col.field, 'dateRangeFilter')"
              [(ngModel)]="tableService.filterValues[col.field]"
              appHighlightInputWithValue
              (onClearClick)="dt.filter(null, col.field, 'dateRangeFilter')"
              [style]="{ minWidth: '100%' }"
              dateFormat="dd.mm.yy"
              [showButtonBar]="true"
            >
            </p-calendar>
              <input
                *ngSwitchDefault
                type="text"
                placeholder="{{ 'Filter' }}"
                [(ngModel)]="tableService.filterValues[col.field]"
                appHighlightInputWithValue
                (input)="dt.filter($event.target.value, col.field, 'contains')"
              />
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-index="rowIndex" let-columns="columns">
          <tr [pSelectableRow]="rowData">
            <td>{{ index + 1 }}</td>
            <td *ngFor="let col of columns" [ngSwitch]="col.field">
              <div *ngSwitchCase="'action'" class="centercontent">
                <button class="action" (click)="onDownloadInvoice(rowData)">
                  <i class="fa fa-search" aria-hidden="true"></i>
                  <div class="label">{{ 'Download' }}</div>
                </button>
              </div>
              <div *ngSwitchCase="'invoiceDate'">
                {{ rowData[col.field] | date: 'HH:mm dd.MM.yyyy' }}
              </div>
              <div *ngSwitchCase="'invoiceDateDue'">
                {{ rowData[col.field] | date: 'HH:mm dd.MM.yyyy' }}
              </div>
              <div *ngSwitchDefault>
                {{ rowData[col.field] }}
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </app-loader>
</div>
