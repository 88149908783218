import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { from } from 'rxjs';
import { AddCustomerAddressUnavailability } from 'src/app/history/models/add-address-unavailability.model';
import { Status } from 'src/app/history/models/request-status.type';
import { SchedulesService } from 'src/app/history/services/schedules.service';
import { FullCallendarConsts } from 'src/app/_shared/constants/full-calendar.const';
import { ToastsService } from 'src/app/_shared/services/toasts.service';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-add-unavailability',
  templateUrl: './add-unavailability.component.html',
  styleUrls: ['./add-unavailability.component.scss'],
})
export class AddUnavailabilityComponent implements OnInit {
  constructor(
    private ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private schedulesService: SchedulesService,
    private toastsService: ToastsService,
    public translate: TranslateService,
    private confirmationService: ConfirmationService
  ) {
    this.selectedAddressData = config.data;
  }

  addForm: FormGroup;
  calendarLocale = FullCallendarConsts.getCallendarLocale();
  MinAvailableDate: Date;
  selectedAddressData: any;

  ngOnInit(): void {
    this.addForm = new FormGroup({
      comment: new FormControl(),
      from: new FormControl(),
      to: new FormControl(),
    });
    this.getMinAvailableDate();
    this.addForm.setValidators(this.dataComparisonValidator());
  }

  getMinAvailableDate() {
    let currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 7);
    this.MinAvailableDate = currentDate;
  }

  dataComparisonValidator() {
    return (group: FormGroup): ValidationErrors => {
      const fromDate = group.controls['from'];
      const toDate = group.controls['to'];
      if (toDate.value != null && fromDate.value != null) {
        if (toDate.value < fromDate.value) {
          toDate.setErrors({ notEqual: true });
          fromDate.setErrors({ notEqual: true });
        } else {
          toDate.markAsDirty;
          fromDate.markAsDirty;
          toDate.setErrors(null);
          fromDate.setErrors(null);
        }
      }
      return;
    };
  }

  onCancel() {
    this.ref.destroy();
  }

  onSave() {
    const request: AddCustomerAddressUnavailability = {
      comment: this.addForm.controls.comment.value,
      from: this.addForm.controls.from.value as Date,
      to: this.addForm.controls.to.value as Date,
      addressId: this.selectedAddressData.selectedAddressId,
      status: Status.Requested,
    };

    this.confirmationService.confirm({
      header: this.translate.instant('alert.confirmation'),
      message:
        this.translate.instant('alert.pleaseConfirmRequest') +
        ' ' +
        formatDate(request.from, 'dd-MM-yyyy', 'en-GB') +
        ' ' + 
        this.translate.instant('alert.to') +
        ' ' +
        formatDate(request.to, 'dd-MM-yyyy', 'en-GB'),
      accept: () =>
        this.schedulesService.addNewUnavailbility(request).subscribe(
          (res) => {
            this.ref.close();
            this.toastsService.showSuccess(this.translate.instant(this.translate.instant('alert.requestSend') + '!'));
          },
          (error) => this.toastsService.showError(error)
        ),
    });
  }
}
