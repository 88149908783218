import { AddressVisit } from 'src/app/_shared/models/address-visit.model';
import { VolumeSummary } from './volume-summary.model';

export class ExtendedAddressVisit extends AddressVisit {
  volumeSummary: VolumeSummary[];
  dateData: string;
  articleData: string;
  volumeData: string;

  public constructor(init?: AddressVisit) {
    super();
    Object.assign(this, init);
  }
}
