import { Component, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/api/selectitem';
import { VolumeSummary } from './../models/volume-summary.model';
import { DatePipe } from '@angular/common';
import { ExtendedAddressVisit } from './../models/extended-address-visit.model';
import { ArticleUnitTypePipe } from '../../_shared/pipes/article-unit-type.pipe';
import { BlobDownloader } from '../../_shared/utils/blob-dowloader';
import { ToastsService } from '../../_shared/services/toasts.service';
import { HistoryService } from '../../_shared/services/data.service';
import { CollectionInfo } from '../../_shared/models/collection-info.model';
import { Address } from '../../_shared/models/address.model';
import { FullCallendarConsts } from 'src/app/_shared/utils/full-calendar.const';

import * as moment from 'moment';
import { List } from 'linqts';
import { TimeConverter } from 'src/app/_shared/utils/time.converter';
import { IsccSelfDeclarationDocument } from 'src/app/_shared/models/iscc-self-declaration-document.model';
import { TradingDocument } from 'src/app/_shared/models/trading-document.model';
import { TableQueryService } from 'src/app/_shared/services/table.service';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
})
export class HistoryComponent implements OnInit {
  addresses: SelectItem[];
  visibleAddresses: SelectItem[];
  selectedAddress: Address;
  addressVisits: ExtendedAddressVisit[];
  visibleAddressVisits: ExtendedAddressVisit[] = [];
  totalPickupCount: string;
  totalPickupVolume: string;
  rangeDates: Date[] = [moment().startOf('year').toDate(), moment().endOf('year').toDate()];
  cols = [
    { field: 'date', header: 'common.date', width: '20%' },
    { field: 'articleData', header: 'history.articles', width: '40%' },
    { field: 'volumeData', header: 'history.volume', width: '20%' },
    { field: 'tradingDoc', header: 'history.documents', width: '10%' },
    { field: 'isccDoc', header: 'history.iscc', width: '10%' },
    // { field: 'invoiceDoc', header: 'Invoices', width: '10%' },
  ];
  addressesLoader = 'addressesLoader';
  addressVisitsLoader = 'addressVisitsLoader';

  calendarLocale = FullCallendarConsts.getCallendarLocale();

  constructor(
    private toastsService: ToastsService,
    private historyService: HistoryService,
    private datePipe: DatePipe,
    private articleUnitTypePipe: ArticleUnitTypePipe,
    public tableService: TableQueryService,
  ) {tableService.init()}

  ngOnInit(): void {
    this.historyService.getAddresses(this.addressesLoader).subscribe(
      (result) => {
        this.addresses = result.map((a) => ({ label: a.name, value: a }));
        this.visibleAddresses = this.addresses;
        this.selectedAddress = result.length > 0 ? result[0] : null;
        if (this.selectedAddress) {
          this.getHistory();
        }
      },
      (error) => {
        this.toastsService.showError(error);
      }
    );
  }

  onSelectAddress() {
    this.getHistory();
  }

  download(doc: TradingDocument, date: string) {
    this.historyService.getTradingDocument(doc.id).subscribe(
      (blob) => {
        const downloader = new BlobDownloader(window, document);
        downloader.downloadBlob(
          blob,
          (doc.isRemoved ? '(DEPRECATED)' : '') + `${this.selectedAddress.name}-${this.datePipe.transform(date, 'dd_MM_y')}.pdf`
        );
      },
      (error) => {
        (error as Blob).text().then((value) => this.toastsService.showError(value));
      }
    );
  }

  downloadIscc(doc: IsccSelfDeclarationDocument, date: string) {
    this.historyService.getIsccDocument(doc.id).subscribe(
      (blob) => {
        const downloader = new BlobDownloader(window, document);
        downloader.downloadBlob(blob.body, (doc.isRemoved ? '(DEPRECATED)' : '') + blob.headers.get('x-file-name'));
      },
      (error) => {
        (error as Blob).text().then((value) => this.toastsService.showError(value));
      }
    );
  }

  downloadInvoice(tradingDocumentId: number) {
    this.historyService.getInvoiceDocument(tradingDocumentId).subscribe(
      (blob) => {
        const downloader = new BlobDownloader(window, document);
        downloader.downloadBlob(blob.body, blob.headers.get('x-file-name'));
      },
      (error) => {
        (error as Blob).text().then((value) => this.toastsService.showError(value));
      }
    );
  }

  private getHistory() {
    this.addressVisits = [];
    this.historyService.getAddressCollections(this.selectedAddress.id, this.addressVisitsLoader).subscribe(
      (result) => {
        result.forEach((addressVisit) => {
          const extendedAddressVisit = new ExtendedAddressVisit(addressVisit);
          extendedAddressVisit.dateData = this.datePipe.transform(addressVisit.date, 'dd.MM.y');
          extendedAddressVisit.articleData = addressVisit.collections.map((c) => c.articleName).join(' ');
          extendedAddressVisit.volumeData = addressVisit.collections
            .filter((c) => c.volume)
            .map((c) => `${c.volume} ${this.articleUnitTypePipe.transform(c.unitType)}`)
            .join(' ');
          extendedAddressVisit.volumeSummary = this.getVolumeSummary(addressVisit.collections);
          this.addressVisits.push(extendedAddressVisit);
        });

        this.onPeriodChanged();
      },
      (error) => {
        this.toastsService.showError(error);
      }
    );
  }

  private getVolumeSummary(collections: CollectionInfo[]): VolumeSummary[] {
    const summary: VolumeSummary[] = [];
    collections.forEach((collection) => {
      if (collection.volume) {
        const type = summary.find((i) => i.unit === collection.unitType);
        if (type) {
          type.volume += collection.volume;
        } else {
          summary.push(new VolumeSummary({ unit: collection.unitType, volume: collection.volume }));
        }
      }
    });
    return summary;
  }

  onPeriodChanged() {
    this.visibleAddressVisits = this.addressVisits.filter(
      (x) =>
        new Date(x.date) >= this.rangeDates[0] && new Date(x.date) <= TimeConverter.getMaxTime(this.rangeDates[1] ?? this.rangeDates[0])
    );

    this.totalPickupCount = new List(this.visibleAddressVisits).Sum((x) => x.collections.length).toString();
    this.totalPickupVolume = new List(this.visibleAddressVisits).Sum((x) => new List(x.collections).Sum((x) => x.volume)).toString();
  }

  onSearchAddress(event) {
    let query = event.target.value.toLowerCase();

    const result = new List(this.addresses)
      .Where((x) => [x.value.name, x.value.city, x.value.street].join(' ').toLowerCase().indexOf(query) !== -1)
      .ToArray();

    this.visibleAddresses = [...result];
  }
}
