import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'find' })

export class FindPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) { }

  transform(value: any[], property: any, propertyValue: any, returnProperty: string = null) {

    if (!value || !property || !propertyValue) {
      return value;
    }
    const result = value.find(x => x[property] == propertyValue);

    return result && returnProperty
      ? result[returnProperty]
      : result
  }
}
