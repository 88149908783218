<div class="white-container full-height p-0" >
  <div class="p-grid full-height nopad" >
    <div class="p-col-4 full-height p-0">
      <app-loader [name]="addressesLoader">
        <div class="navigation-card-container full-height">
          <div class="p-10">
            <div class="p-col-12 p-xl-6 addresses__header inline-block ">
              {{ 'web.common.addresses' | translate }}
            </div>
            <div class="p-col-12 p-xl-6 inline-block p-0">
              <input pInputText type="text" placeholder="Search.." (input)="onSearchAddress($event)" />
            </div>
            <div class="addresses-container">
              <p-listbox class="address-selector" [options]="visibleAddresses" [(ngModel)]="selectedAddress" (onChange)="onSelectAddress()">
                <ng-template let-address pTemplate="item">
                  <span>{{ address.value.name }}</span>
                  <span class="addresses__city">{{ address.value.city }}</span>
                  <span>{{ address.value.street }}</span>
                </ng-template>
              </p-listbox>
            </div>
          </div>
        </div>
      </app-loader>
    </div>

    <div class="p-col-8 collections p-0">
      <div class="data-header-container p-10">
        <div class="header-container-data-col">
          <p-calendar
            selectionMode="range"
            [(ngModel)]="rangeDates"
            [inputStyle]="{ width: '100%' }"
            [style]="{ width: '100%' }"
            placeholder="Select period"
            dateFormat="dd-mm-yy"
            [locale]="calendarLocale"
            [monthNavigator]="true"
            (ngModelChange)="onPeriodChanged()"
            [yearNavigator]="true"
            yearRange="2015:2050"
            appendTo="body">
          </p-calendar>
        </div>
        <div class="header-container-data-col">
          Total pickups: <span class="collections__header-summary-item">{{ totalPickupCount }}</span>
        </div>
        <div class="header-container-data-col">
          Total volume: <span class="collections__header-summary-item">{{ totalPickupVolume }} Kg</span>
        </div>
      </div>
      <app-loader [name]="addressVisitsLoader">
        <p-table #table class="collections-history" [value]="visibleAddressVisits" [columns]="cols">
          <ng-template pTemplate="header" let-columns>
            <tr>
              <ng-template ngFor let-col [ngForOf]="columns" let-i="index">
                <th *ngIf="col.field === 'date'" [pSortableColumn]="'date'" [ngStyle]="{ width: col.width }">
                  <div class="collections__column-header search-input">
                    <span class="pi pi-sort collections__sort-icon"></span>
                    <span>
                      {{ col.header | translate }}
                    </span>
                    <span class="pi pi-search collections__search-icon"></span>
                      <p-calendar
                      #rangeFilter1
                      selectionMode="range"
                      appendTo="body"
                      placeholder="{{'common.date' | translate}}"
                      [locale]="tableService.calendarLocale"
                      (onSelect)="table.filter(rangeFilter1.value, col.field, 'dateRangeFilter')"
                      [(ngModel)]="tableService.filterValues[col.field]"
                      appHighlightInputWithValue
                      (onClearClick)="table.filter(null, col.field, 'dateRangeFilter')"
                      [style]="{ minWidth: '100%' }"
                      dateFormat="dd.mm.yy"
                      [showButtonBar]="true"
                      (click)="$event.stopPropagation()"
                      >
                      </p-calendar>
                  </div>
                </th>
                <th *ngIf="col.field === 'articleData'" [ngStyle]="{ width: col.width }">
                  <div class="collections__column-header search-input">
                    <span>
                      {{ col.header | translate }}
                    </span>
                    <span class="pi pi-search collections__search-icon"></span>
                    <input pInputText type="text" (input)="table.filter($event.target.value, 'articleData', 'contains')"                      
                            placeholder="{{'common.filter' | translate}}"/>
                  </div>
                </th>
                <th *ngIf="col.field === 'volumeData'" [ngStyle]="{ width: col.width }">
                  <div class="collections__column-header search-input">
                    <span>
                      {{ col.header | translate }}
                    </span>
                    <span class="pi pi-search collections__search-icon"></span>
                    <input pInputText type="text" (input)="table.filter($event.target.value, 'volumeData', 'contains')" 
                    placeholder="{{'common.filter' | translate}}" />
                  </div>
                </th>
                <th
                  *ngIf="col.field === 'tradingDoc' || col.field === 'invoiceDoc' || col.field === 'isccDoc'"
                  [ngStyle]="{ width: col.width }"
                >
                  <span>
                    {{ col.header | translate }}
                  </span>
                </th>
              </ng-template>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-visit let-columns="columns">
            <tr>
              <td [ngStyle]="{ width: col.width }" *ngFor="let col of columns" [ngSwitch]="col.field">
                <div *ngSwitchCase="'date'">
                  {{ visit.dateData }}
                </div>
                <div *ngSwitchCase="'articleData'">
                  <div *ngFor="let collection of visit.collections" class="collections__articles-item">
                    {{ collection.articleName }}
                  </div>
                </div>
                <div *ngSwitchCase="'volumeData'">
                  <div *ngFor="let collection of visit.collections" class="collections__articles-item">
                    <span *ngIf="collection.volume"> {{ collection.volume }} {{ collection.unitType | articleUnitType }} </span>
                  </div>
                  <div class="collections__volume-summary">
                    <div *ngFor="let summary of visit.volumeSummary; let i = index">
                      <span class="collections__volume-summary-item">{{ summary.volume }} {{ summary.unit | articleUnitType }}</span>
                      <span *ngIf="i < visit.volumeSummary.length - 1" class="collections__volume-summary-separator">and</span>
                    </div>
                  </div>
                </div>
                <div *ngSwitchCase="'tradingDoc'" class="collections__column-download">
                  <img
                    *ngFor="let tradingDocument of visit.tradingDocuments"
                    pTooltip="{{ tradingDocument.isRemoved ? tradingDocument.fileName + ' (DEPRECATED)' : tradingDocument.fileName }}"
                    tooltipPosition="bottom"
                    class="collections__column-download-icon"
                    src="{{
                      tradingDocument.isRemoved ? './../../assets/icons/document_deprecated.svg' : './../../assets/icons/document.svg'
                    }}"
                    (click)="download(tradingDocument, visit.date)"
                  />
                </div>
                <div *ngSwitchCase="'isccDoc'" class="collections__column-download">
                  <img
                    *ngFor="let isccDocument of visit.isccDocuments"
                    pTooltip="{{ isccDocument.isRemoved ? isccDocument.fileName + ' (DEPRECATED)' : isccDocument.fileName }}"
                    tooltipPosition="bottom"
                    class="collections__column-download-icon iscc"
                    src="{{
                      isccDocument.isRemoved ? './../../assets/icons/document_deprecated.svg' : './../../assets/icons/document.svg'
                    }}"
                    (click)="downloadIscc(isccDocument, visit.date)"
                  />
                </div>
                <div *ngSwitchCase="'invoiceDoc'" class="collections__column-download">
                  <img
                    *ngFor="let invoiceId of visit.invoiceIds"
                    class="collections__column-download-icon"
                    src="./../../assets/icons/document.svg"
                    (click)="downloadInvoice(invoiceId)"
                  />
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </app-loader>
    </div>
  </div>
</div>
