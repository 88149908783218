import { ArticleUnitType } from 'src/app/_shared/models/article-unit-type.enum';

export class VolumeSummary {
  volume: number;
  unit: ArticleUnitType;

  public constructor(init?: Partial<VolumeSummary>) {
    Object.assign(this, init);
  }
}
