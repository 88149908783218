import { Pipe, PipeTransform } from '@angular/core';
import { ArticleUnitType } from '../models/article-unit-type.enum';

@Pipe({
  name: 'articleUnitType',
})
export class ArticleUnitTypePipe implements PipeTransform {
  transform(value: ArticleUnitType): string {
    switch (value) {
      case ArticleUnitType.Items:
        return 'Items';
      case ArticleUnitType.Kg:
        return 'Kg';
      case ArticleUnitType.Liters:
        return 'L';
      default:
        return '';
    }
  }
}
