import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AuthService } from '../services/auth.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      retry(0),
      catchError((error: HttpErrorResponse) => {
        console.error(error);
        let message = '';

        if (error.error) {
          message = error.error;
        } else {
          switch (error.status) {
            case 406:
            case 409:
            case 500:
              if (typeof (error.error) == typeof (new Blob())) {
                message = "File not found";
              }
              else{
                message = error.error;
              }
              break;
            case 404:
              message = 'Page not found';
              break;
            case 401:
              message = 'Unauthorized operation';
              this.authService.login();
              break;
            case 0:
              message = 'Server cannot be reached';
              break;
            default:
              message = 'Unknown error';
              break;
          }
        }
        return throwError(message);
      })
    );
  }
}
