import { ArrayMapPipe } from './pipes/array-map.pipe';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TooltipModule } from 'primeng/tooltip';
import { BreadcrumbComponent } from './components/layout/breadcrumb/breadcrumb.component';
import { LayoutComponent } from './components/layout/layout.component';
import { LeftMenuComponent } from './components/layout/left-menu/left-menu.component';
import { TopNavComponent } from './components/layout/top-nav/top-nav.component';
import { LoaderComponent } from './components/loaders/loader/loader.component';
import { ArticleUnitTypePipe } from './pipes/article-unit-type.pipe';
import { ToastModule } from 'primeng/toast';
import { FlagEnumText } from './pipes/flag-enum-text.pipe';
import { FindPipe } from './pipes/find.pipe';
import { CheckboxModule } from 'primeng/checkbox';

@NgModule({
  declarations: [
    ArticleUnitTypePipe,
    LoaderComponent,
    LayoutComponent,
    TopNavComponent,
    LeftMenuComponent,
    BreadcrumbComponent,
    ArrayMapPipe,
    FindPipe,
    FlagEnumText,
  ],
  imports: [CommonModule, RouterModule, ToastModule, TooltipModule, CheckboxModule],
  exports: [ArticleUnitTypePipe, LoaderComponent, ArrayMapPipe, FindPipe, FlagEnumText, CheckboxModule],
})
export class SharedModule {}
