<div class="mycontainer p-grid p-nogutter">
  <div class="p-col-6 max-height">
    <div class="white-container left-container p-0">
      <div class="addresses-list-container">
        <app-loader [name]="addressesLoader">
          <div class="flex table-header-container">
            <div class="p-col-8 select-all">
              <p-checkbox
                name="groupname"
                binary="true"
                (onChange)="onSelectAllChanged($event)"
                label="{{ 'common.selectAll' | translate }}"
              ></p-checkbox>
            </div>
            <div class="p-col-4">
              <input pInputText type="text" placeholder="Search.." (input)="onSearchAddress($event)" />
            </div>
          </div>

          <div class="pad-container">
            <p-table
              #dt
              [value]="visibleAddresses"
              [scrollable]="true"
              scrollHeight="270px"
              (onRowSelect)="onNeighbourSelectionChange()"
              (onRowUnselect)="onNeighbourSelectionChange()"
              [(selection)]="selectedAddresses"
              dataKey="id"
            >
              <ng-template pTemplate="colgroup" let-columns>
                <colgroup>
                  <col [style.width]="'3rem'" />
                  <col [style.width]="'50px'" />
                  <col [style.width]="'30%'" />
                  <col [style.width]="'20%'" />
                  <col [style.width]="'100px'" />
                </colgroup>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr [pSelectableRow]="rowData">
                  <td>
                    <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                  </td>
                  <td class="bold-text">
                    {{ rowData.letterId }}
                  </td>
                  <td>
                    {{ rowData.name }}
                  </td>
                  <td>
                    {{ rowData.city + ', ' + rowData.street }}
                  </td>
                  <td>
                    <div class="pull-right">
                      <button class="submit save-btn-position" (click)="onAddressSelected(rowData.id)">
                        <i class="fa fa-edit" aria-hidden="true"></i>
                        <div class="label">{{ 'Select' | translate }}</div>
                      </button>
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </app-loader>
      </div>

      <form [formGroup]="editForm" *ngIf="editForm">
        <div class="horizontal-divider"></div>
        <app-loader [name]="detailsLoader">
          <div class="pad-container">
            <div *ngIf="selectedAddressData" class="my-tab-view">
              <div class="title">
                <div class="title-label">
                  <span>[{{ selectedAddressData.letterId }}]</span> {{ selectedAddressData.name }}
                </div>
                <div class="subtitle-label">
                  <span>{{ selectedAddressData.city + ' ' + selectedAddressData.street + ', ' + selectedAddressData.countryName }}</span>
                </div>
              </div>

              <div class="content">
                <div class="p-grid">
                  <div class="p-col-6">
                    <div class="margin-left-05em">{{ 'management.unavailabilities' | translate }}</div>
                  </div>
                  <div class="p-col-6">
                    <button class="submit save-btn-position pull-right" (click)="onAddNew(selectedAddressData.id)">
                      <i class="fa fa-floppy-o" aria-hidden="true"></i>
                      <div class="label">{{ 'common.addNew' | translate }}</div>
                    </button>
                  </div>
                </div>

                <div class="unavailabilities-container">
                  <div class="action-row">
                    <span class="action-row-column column-comment">{{ 'management.comment' | translate }}</span>
                    <span class="action-row-column column-date">{{ 'common.from' | translate }}</span>
                    <span class="action-row-column column-date">{{ 'common.to' | translate }}</span>
                    <span class="action-row-column column-status">{{ 'common.status' | translate }}</span>
                  </div>
                  <ng-container formArrayName="customerAddressUnavailability">
                    <ng-container *ngFor="let unavailability of editForm.get('customerAddressUnavailability')['controls']; let i = index">
                      <div class="action-row" [formGroupName]="i">
                        <span class="action-row-column column-comment">{{ unavailability.value.comment }}</span>
                        <span class="action-row-column column-date">{{ unavailability.value.from | date: 'dd/MM/yyyy' }}</span>
                        <span class="action-row-column column-date">{{ unavailability.value.to | date: 'dd/MM/yyyy' }}</span>
                        <span class="action-row-column column-status">
                          <div class="status-img">
                            <img
                              *ngIf="unavailability.value.status == 1"
                              src="../../../../assets/icons/schedule/pending.svg"
                              pTooltip="{{ 'common.unavailabilityPending' | translate }}"
                              tooltipPosition="bottom"
                            />
                            <img
                              *ngIf="unavailability.value.status == 2"
                              src="../../../../assets/icons/schedule/confirm.svg"
                              pTooltip="{{ 'common.unavailabilityConfirmed' | translate }}"
                              tooltipPosition="bottom"
                            />
                          </div>
                        </span>
                      </div>
                    </ng-container>
                  </ng-container>
                </div>
                <div class="information">{{ 'selectedAddressData.information' | translate }}</div>
              </div>
            </div>
          </div>
        </app-loader>
      </form>
    </div>
  </div>

  <div class="p-col-6 max-height">
    <div class="white-container right-container nopad">
      <app-loader [name]="scheduleLoader">
        <div class="address-calendar-container">
          <div class="p-10">
            <p-fullCalendar #fullCalendar class="pickups-calendar myCalendar" [events]="calendarEvents" [options]="calendarOptions">
            </p-fullCalendar>
          </div>
        </div>
        <hr class="separation-line">
        <div class="event-details-container">
          <div class="p-10" *ngIf="hoveredEvent; else noHoveredEvent">
            <div *ngIf="hoveredEvent.type == CalendarEventType.ScheduledPickup" class="p-grid p-ai-center">
              <div class="col-p-2 p-col-8 bold-text">
                {{ hoveredEvent.title + ' ' + (addresses | find: 'id':hoveredEvent.customerAddressId:'name') }}
              </div>
              <div class="col-p-2 p-col-4 bold-text">{{ 'management.scheduled' | translate }}</div>
              <div class="col-p-2 p-col-2">{{ 'management.contract' | translate }}:</div>
              <div class="col-p-2 p-col-5">{{ hoveredEvent.contractName }}</div>
              <div class="col-p-2 p-col-2">{{ 'management.pickupType' | translate }}:</div>
              <div class="col-p-2 p-col-3">{{ hoveredEvent.vehicleType == VehicleType.ContainersCollector ? 'Exchange' : 'Dump' }}</div>

              <div class="col-p-2 p-col-2">{{ 'management.goodsDestination' | translate }}:</div>
              <div class="col-p-2 p-col-5">{{ hoveredEvent.goodsDestinationName }}</div>
              <div class="col-p-2 p-col-2">{{ 'management.period' | translate }}:</div>
              <div class="col-p-2 p-col-3">
                {{ (hoveredEvent.startingDate | date: 'dd.MM.yyyy') + ' - ' + (hoveredEvent.expirationDate | date: 'dd.MM.yyyy') }}
              </div>

              <div class="col-p-2 p-col-2">{{ 'management.depot' | translate }}:</div>
              <div class="col-p-2 p-col-5">{{ hoveredEvent.companyAddressName }}</div>
              <div class="col-p-2 p-col-2">{{ 'management.daysOrRepeat' | translate }}:</div>
              <div class="col-p-2 p-col-3">
                {{
                  (hoveredEvent.weekDays | flagEnumText: workdays) + ' / ' + (repetitions | find: 'value':hoveredEvent.repetition:'label')
                }}
              </div>

              <div *ngIf="hoveredEvent.vehicleType == VehicleType.ContainersCollector" class="col-p-2 p-col-2">
                {{ 'management.containers' | translate }}:
              </div>
              <div *ngIf="hoveredEvent.vehicleType == VehicleType.ContainersCollector" class="col-p-2 p-col-3">
                {{ hoveredEvent.assetDefinitionName + ' Qty: ' + hoveredEvent.containersQuantity }}
              </div>
            </div>
            <div *ngIf="hoveredEvent.type == CalendarEventType.OnDemandPickup" class="p-grid">
              <div class="col-p-2 p-col-8 bold-text">
                {{ hoveredEvent.title + ' ' + (addresses | find: 'id':hoveredEvent.customerAddressId:'name') }}
              </div>
              <div class="col-p-2 p-col-4 bold-text">{{ 'web.management.onDemand' | translate }}</div>
              <div class="col-p-2 p-col-2">{{ 'management.contract' | translate }}:</div>
              <div class="col-p-2 p-col-5">{{ hoveredEvent.contractName }}</div>
              <div class="col-p-2 p-col-2">{{ 'management.pickupType' | translate }}:</div>
              <div class="col-p-2 p-col-3">{{ hoveredEvent.vehicleType == VehicleType.ContainersCollector ? 'Exchange' : 'Dump' }}</div>

              <div class="col-p-2 p-col-2">{{ 'management.transporter' | translate }}:</div>
              <div class="col-p-2 p-col-5">{{ hoveredEvent.transportingCompanyName }}</div>
              <div class="col-p-2 p-col-2">{{ 'management.date' | translate }}:</div>
              <div class="col-p-2 p-col-3">
                {{ hoveredEvent.pickupDate | date: 'dd.MM.yyyy' }}
                <span *ngIf="hoveredEvent.maxDelayedDays > 0">{{
                  ' + ' + hoveredEvent.maxDelayedDays + ' ' + ('management.days' | translate)
                }}</span>
              </div>

              <div class="col-p-2 p-col-2">{{ 'management.depot' | translate }}:</div>
              <div class="col-p-2 p-col-5">{{ hoveredEvent.companyAddressName }}</div>
              <div class="col-p-2 p-col-2">{{ 'management.category' | translate }}:</div>
              <div class="col-p-2 p-col-3">{{ articleCategories | find: 'value':hoveredEvent.articleCategory:'label' }}</div>

              <div class="col-p-2 p-col-2">{{ 'management.goodsDestination' | translate }}:</div>
              <div class="col-p-2 p-col-5">{{ hoveredEvent.goodsDestinationName }}</div>
              <div class="col-p-2 p-col-2">
                <span *ngIf="hoveredEvent.vehicleType == VehicleType.ContainersCollector">{{ 'management.containers' | translate }}:</span>
              </div>
              <div class="col-p-2 p-col-3">
                <span *ngIf="hoveredEvent.vehicleType == VehicleType.ContainersCollector">
                  {{ hoveredEvent.assetDefinitionName + ' Qty: ' + hoveredEvent.containersQuantity }}
                </span>
              </div>

              <div class="col-p-2 p-col-2" *ngIf="hoveredEvent.plannedDate"></div>
              <div class="col-p-2 p-col-5" *ngIf="hoveredEvent.plannedDate"></div>

              <div *ngIf="hoveredEvent.plannedDate" class="col-p-2 p-col-2">{{ 'management.ptvPlannedDate' | translate }}:</div>
              <div *ngIf="hoveredEvent.plannedDate" class="col-p-2 p-col-3">{{ hoveredEvent.plannedDate | date: 'dd.MM.yyyy' }}</div>
            </div>
            <div *ngIf="hoveredEvent.type == CalendarEventType.Unavailability" class="p-grid">
              <div class="col-p-2 p-col-8 bold-text">
                {{ hoveredEvent.title + ' ' + (addresses | find: 'id':hoveredEvent.customerAddressId:'name') }}
              </div>
              <div class="col-p-2 p-col-4 bold-text">{{ 'web.masterData.customerAddressUnavailability' | translate }}</div>
              <div class="col-p-2 p-col-12">{{ hoveredEvent.comment }}</div>
            </div>
            <div *ngIf="hoveredEvent.type == CalendarEventType.Holiday" class="p-grid">
              <div class="col-p-2 p-col-12 bold-text">{{ 'web.masterData.holiday' | translate }}</div>
              <div class="col-p-2 p-col-12">{{ hoveredEvent.comment }}</div>
            </div>
          </div>
          <ng-template #noHoveredEvent> </ng-template>
        </div>
      </app-loader>
    </div>
  </div>
</div>
