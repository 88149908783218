import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { LocalStorageService } from './local-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { Language } from '../models/language.model';
import { BaseService } from './_base.service';

@Injectable({
  providedIn: 'root',
})
export class LocalizationService extends BaseService {
  private availableLanguages: Array<Language> = [];
  private currentLang: string = null;

  availableLanguagesObs = new BehaviorSubject<Array<Language>>(this.availableLanguages);

  constructor(private localStorageService: LocalStorageService, private http: HttpClient, private translate: TranslateService) {
    super();
    setTimeout(() => this.init(), 0);
  }

  private init() {
    this.translate.setDefaultLang('en');
    this.translate.use(this.getCurrentLanguage());
    this.translate.onLangChange.subscribe((x) => this.setLanguage(x.lang));
    this.getAllPublishedLanguages();
  }

  setLanguage(lang: string) {
    this.translate.use(lang);
    this.currentLang = lang;
    this.localStorageService.store(LocalStorageService.PREFERENCES_LANGUAGE, lang);
  }

  getCurrentLanguage(): string {
    if (!this.currentLang) {
      this.currentLang = this.localStorageService.load(LocalStorageService.PREFERENCES_LANGUAGE);
      if (!this.currentLang || this.currentLang === 'keys') {
        this.setLanguage('en');
      }
    }
    return this.currentLang;
  }

  getAllLanguages(): Observable<Array<Language>> {
    return this.availableLanguagesObs.asObservable();
  }

  getAllPublishedLanguages() {
    this.http.get<Array<Language>>(this.baseUrl + 'translations/languages/published').subscribe((x) => {
      this.availableLanguages = x;
      this.availableLanguagesObs.next(this.availableLanguages);
      this.translate.addLangs(x.map((l) => l.culture));
    });
  }

  showTranslationsKeys() {
    const translation = Object.assign({}, this.translate.translations.en);
    const keys = Object.keys(translation);

    keys.forEach((x) => {
      translation[x] = x;
    });

    this.translate.addLangs(['keys']);
    this.translate.setTranslation('keys', translation);
    setTimeout(() => this.translate.use('keys'), 0);
  }
}
