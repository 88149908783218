import { Component, OnInit } from '@angular/core';
import { TableQueryService } from 'src/app/_shared/services/table.service';
import { ToastsService } from 'src/app/_shared/services/toasts.service';
import { BlobDownloader } from 'src/app/_shared/utils/blob-dowloader';
import { InvoiceOdoo } from '../../models/Invoice-odoo.model';
import { OdooInvoiceType } from '../../models/odoo-invoice.type';
import { OdooService } from '../../services/odoo.service';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss'],
})
export class InvoicesComponent implements OnInit {
  invoices: Array<InvoiceOdoo> = [];
  invoicesLoader = 'invoicesLoader';
  types = [];

  cols = [
    { field: 'name', header: 'common.name' },
    { field: 'typeDescription', header: 'common.type' },
    { field: 'invoicePartnerDisplayName', header: 'invoice.partner' },
    { field: 'company', header: 'invoice.company' },

    { field: 'invoiceDateDue', header: 'invoice.dateDue' },
    { field: 'invoiceDate', header: 'invoice.date' },

    { field: 'amountTax', header: 'invoice.amountTax' },
    { field: 'amountTotal', header: 'invoice.amountTotal' },
    { field: 'state', header: 'invoice.state' },
    { field: 'action', header: 'common.action', width: '145px' },
  ];

  constructor(private odooService: OdooService, public tableService: TableQueryService, private toastsService: ToastsService) {
    tableService.init();
  }

  ngOnInit(): void {
    this.odooService.getInvoices(this.invoicesLoader).subscribe(
      (x) => {
        this.invoices = x;
      },
      (error) => {
        this.toastsService.showError(error);
      }
    );
  }

  onDownloadInvoice(invoice: InvoiceOdoo) {
    if (invoice.type == OdooInvoiceType.Proforma) {
      this.odooService.getProformaDocument(invoice.id).subscribe(
        (blob) => {
          const downloader = new BlobDownloader(window, document);
          downloader.downloadBlob(blob.body, blob.headers.get('x-file-name'));
        },
        (error) => {
          this.toastsService.showError(error);
        }
      );
    } else {
      this.odooService.getInvoiceDocument(invoice.id).subscribe(
        (blob) => {
          const downloader = new BlobDownloader(window, document);
          downloader.downloadBlob(blob.body, blob.headers.get('x-file-name'));
        },
        (error) => {
          this.toastsService.showError(error);
        }
      );
    }
  }
}
