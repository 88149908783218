import { Component } from '@angular/core';
import { ToastsService } from './_shared/services/toasts.service';

@Component({
  selector: 'app-root',
  template: `
    <p-toast (onClose)="onToastClose($event)"></p-toast>
    <router-outlet></router-outlet>
  `,
})
export class AppComponent {
  constructor(private toastsService: ToastsService) {}

  onToastClose(event) {
    this.toastsService.onToastClose(event.message);
  }
}
