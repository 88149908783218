export class BlobDownloader {
  constructor(private window: Window & typeof globalThis, private document: Document) {}

  public downloadBlob(blob: Blob, fileName: string) {
    const url = this.window.URL.createObjectURL(blob);

    const a = this.document.createElement('a');
    this.document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    a.href = url;
    a.download = fileName;
    a.click();
    this.window.URL.revokeObjectURL(url);
    a.remove();
  }
}
