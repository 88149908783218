<p-confirmDialog></p-confirmDialog>
<div class="modal-container">
  <div class="modal-body-container">
    <form [formGroup]="addForm">
      <div class="grid">
        <div class="col-11 input-container">
          <div class="label">{{ 'management.comment' | translate }}</div>
          <textarea formControlName="comment" autocomplete="off" cols="5" rows="3" [required]="true"></textarea>
        </div>
        <div class="col-4 input-container">
          <div class="label">{{ 'common.from' | translate }}</div>
          <p-calendar
            appendTo="body"
            [locale]="calendarLocale"
            formControlName="from"
            dateFormat="dd.mm.yy"
            [minDate]="MinAvailableDate"
            [showButtonBar]="true"
            [required]="true"
          >
          </p-calendar>
        </div>
        <div class="col-4 input-container">
          <div class="label">{{ 'common.to' | translate }}</div>
          <p-calendar
            appendTo="body"
            [locale]="calendarLocale"
            formControlName="to"
            dateFormat="dd.mm.yy"
            [minDate]="MinAvailableDate"
            [showButtonBar]="true"
            [required]="true"
          >
          </p-calendar>
        </div>

        <div class="col-4 error-container" *ngIf="addForm.controls['from'].errors?.notEqual">{{ 'common.dateNotValid' | translate }}</div>

        <div class="col-11 filler"></div>
        <div class="col-11">
          <div class="col-2 header-btn-container align-right">
            <button
              class="submit align-right"
              (click)="onSave()"
              [disabled]="
                addForm.controls['from'].errors?.notEqual ||
                !addForm.controls['from'].dirty ||
                !addForm.controls['to'].dirty ||
                !addForm.controls['comment'].value
              "
            >
              <i class="fa fa-floppy-o" aria-hidden="true"></i>
              <div class="label">{{ 'common.save' | translate }}</div>
            </button>
          </div>
          <div class="col-2 header-btn-container align-right">
            <button class="cancel align-right" (click)="onCancel()">
              <i class="fa fa-times" aria-hidden="true"></i>
              <div class="label">{{ 'common.cancel' | translate }}</div>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
