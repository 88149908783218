import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  expand: boolean;

  constructor() {}

  ngOnInit(): void {
    const x = localStorage.getItem('expandMenu');
    if (x) {
      this.expand = x === 'true';
    } else {
      this.expand = false;
    }

    this.triggerResizeToSetProperChartSize();
  }

  expandMenu(expand: boolean) {
    this.expand = expand;
    localStorage.setItem('expandMenu', JSON.stringify(expand));
    this.triggerResizeToSetProperChartSize();
  }

  triggerResizeToSetProperChartSize() {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 100);
  }
}
