import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authHeader = this.authService.getAuthorizationHeaderValue();
    if (!authHeader) {
      return next.handle(req);
    }

    const authReq = req.clone({ headers: req.headers.append('Authorization', authHeader) });

    return next.handle(authReq);
  }
}
