import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoadersService } from './loaders.service';
import { finalize, map } from 'rxjs/operators';
import * as dayjs from 'dayjs';
import { Address } from '../models/address.model';
import { AddressVisit } from '../models/address-visit.model';

@Injectable({
  providedIn: 'root',
})
export class HistoryService {
  constructor(private httpClient: HttpClient, private loadersService: LoadersService) {}

  getAddresses(loader?: string): Observable<Address[]> {
    this.loadersService.show(loader);
    return this.httpClient
      .get<Address[]>(`${environment.apiUrl}addresses/user-addresses`)
      .pipe(finalize(() => this.loadersService.hide(loader)));
  }

  getAddressCollections(addressId: number, loader?: string): Observable<AddressVisit[]> {
    this.loadersService.show(loader);
    return this.httpClient.get<AddressVisit[]>(`${environment.apiUrl}addresses/${addressId}/collections`).pipe(
      map((value) =>
        [...value].sort((a, b) => {
          const dateA = dayjs(a.date);
          const dateB = dayjs(b.date);
          if (dateB.isAfter(dateA, 'day')) {
            return 1;
          } else if (dateB.isBefore(dateA, 'day')) {
            return -1;
          } else {
            return 0;
          }
        })
      ),
      finalize(() => this.loadersService.hide(loader))
    );
  }

  getTradingDocument(tradingDocumentId: number): Observable<Blob> {
    return this.httpClient.get<Blob>(`${environment.apiUrl}maintenance/trading-documents/${tradingDocumentId}/download-pdf`, {
      responseType: 'blob' as 'json',
    });
  }

  getIsccDocument(tradingDocumentId: number): Observable<HttpResponse<Blob>> {
    return this.httpClient.get<Blob>(`${environment.apiUrl}maintenance/iscc/${tradingDocumentId}/download-pdf`, {
      observe: 'response',
      responseType: 'blob' as 'json',
    });
  }

  getInvoiceDocument(invoiceId: number): Observable<HttpResponse<Blob>> {
    return this.httpClient.get<Blob>(`${environment.apiUrl}maintenance/invoice/${invoiceId}/download-pdf`, {
      observe: 'response',
      responseType: 'blob' as 'json',
    });
  }
}
