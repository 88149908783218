import { List } from 'linqts';
import { SelectItem } from 'primeng/api';

export class EnumExtension {
  static getLabelAndValues<T extends number>(e: any): SelectItem[] {
    return EnumExtension.getNames(e).map(n => ({ label: n, value: e[n] as T }));
  }

  static getNames(e: any): string[] {
    return EnumExtension.getObjValues(e).filter(v => typeof v === 'string') as string[];
  }

  static getValues<T extends number>(e: any): T[] {
    return EnumExtension.getObjValues(e).filter(v => typeof v === 'number') as T[];
  }

  private static getObjValues(e: any): (number | string)[] {
    return Object.keys(e).map(k => e[k]);
  }

  static getFlagValues<T extends number>(e: any, value: number) {
    const values = this.getLabelAndValues(e);
    const result = new List(values)
      .Where(x => x.value !== 0 && (x.value & value) == x.value)
      .Select(x => x.value)
      .ToArray();

    return result;
  }

  static getSelectedValues<T extends number>(value: number) {
    const values = [1, 2, 4, 8, 16, 32, 64, 128, 256, 512, 1024, 2048];
    const result = new List(values)
      .Where(x => x !== 0 && (x & value) == x)
      .ToArray();

    return result;
  }
}
