<ul class="breadcrumb">
  <li *ngFor="let breadcrumb of breadcrumbs">
    <a *ngIf="!breadcrumb.params" [routerLink]="[breadcrumb.url]" [class.disabled]="!breadcrumb.canNavigateFromBreadcrumb">
      {{ breadcrumb.label }}
    </a>
    <a
      *ngIf="breadcrumb.params"
      [routerLink]="[breadcrumb.url, breadcrumb.params]"
      [class.disabled]="!breadcrumb.canNavigateFromBreadcrumb"
    >
      {{ breadcrumb.label }}
    </a>
  </li>
</ul>
