import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoadersService } from 'src/app/_shared/services/loaders.service';
import { environment } from 'src/environments/environment';
import { InvoiceOdoo } from '../models/Invoice-odoo.model';

@Injectable({
  providedIn: 'root',
})
export class OdooService {
  constructor(private httpClient: HttpClient, private loadersService: LoadersService) {}

  getInvoices(loader?: string): Observable<InvoiceOdoo[]> {
    this.loadersService.show(loader);
    return this.httpClient.get<InvoiceOdoo[]>(`${environment.apiUrl}odoo/invoices`).pipe(finalize(() => this.loadersService.hide(loader)));
  }

  getInvoiceDocument(invoiceId: number): Observable<HttpResponse<Blob>> {
    return this.httpClient.get<Blob>(`${environment.apiUrl}odoo/invoices/${invoiceId}/download-pdf`, {
      observe: 'response',
      responseType: 'blob' as 'json',
    });
  }

  getProformaDocument(invoiceId: number): Observable<HttpResponse<Blob>> {
    return this.httpClient.get<Blob>(`${environment.apiUrl}odoo/proformas/${invoiceId}/download-pdf`, {
      observe: 'response',
      responseType: 'blob' as 'json',
    });
  }
}
